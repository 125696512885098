var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "form-container",
      staticStyle: { "overflow-x": "hidden", "overflow-y": "scroll" },
      style: { height: _vm.height + "px" },
    },
    [
      _c(
        "BaseForm",
        {
          ref: "baseForm",
          staticClass: "mini",
          attrs: {
            formObj: _vm.customer,
            rules: _vm.rules,
            formDate: _vm.customerFormList,
            labelWidth: "100px",
            showlocationMap: "",
            levelList: _vm.levelList,
            addMessage: _vm.addMessage,
            showTopFormItem: "",
            ctmId: _vm.customer.id,
            ygPopList: _vm.ygPopList,
            unitDisabled: _vm.unitDisabled,
            adjustType: "",
            lyEnabled: "",
            showTmLabel: "",
          },
          on: {
            keyUpFun: _vm.handleSave,
            done: function ($event) {
              return _vm.$emit("done")
            },
            clearJsSave: function ($event) {
              return _vm.handleSave("clearJs")
            },
          },
          scopedSlots: _vm._u([
            {
              key: "form-header",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "template",
            { slot: "smcs" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "近3个月内:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.times.time1,
                          callback: function ($$v) {
                            _vm.$set(_vm.times, "time1", _vm._n($$v))
                          },
                          expression: "times.time1",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "近6个月内:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.times.time2,
                          callback: function ($$v) {
                            _vm.$set(_vm.times, "time2", _vm._n($$v))
                          },
                          expression: "times.time2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "近9个月内:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.times.time3,
                          callback: function ($$v) {
                            _vm.$set(_vm.times, "time3", _vm._n($$v))
                          },
                          expression: "times.time3",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "近12个月内:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.times.time4,
                          callback: function ($$v) {
                            _vm.$set(_vm.times, "time4", _vm._n($$v))
                          },
                          expression: "times.time4",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上门次数:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.times.otherTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.times, "otherTime", $$v)
                          },
                          expression: "times.otherTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.getTimes },
                        },
                        [_vm._v("获取其他时间段上门次数")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.dialogVisible3
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "获取时间段",
                visible: _vm.dialogVisible3,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "120px",
                width: "700px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible3 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.times, "label-width": "80px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "起始时间" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      disabled: _vm.flag1,
                                      "value-format": "yyyy-MM-DD",
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.flag2 = true
                                      },
                                    },
                                    model: {
                                      value: _vm.times.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.times, "startDate", $$v)
                                      },
                                      expression: "times.startDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "结束时间" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      "value-format": "yyyy-MM-DD",
                                      disabled: _vm.flag1,
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.flag2 = true
                                      },
                                    },
                                    model: {
                                      value: _vm.times.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.times, "endDate", $$v)
                                      },
                                      expression: "times.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "时间段" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        filterable: "",
                                        disabled: _vm.flag2,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.flag1 = true
                                        },
                                      },
                                      model: {
                                        value: _vm.times.monthNum,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.times, "monthNum", $$v)
                                        },
                                        expression: "times.monthNum",
                                      },
                                    },
                                    _vm._l(
                                      _vm.timeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.title,
                                            value: item.field,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: { click: _vm.getSmTimesList2 },
                                    },
                                    [_vm._v("确定")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          _vm.dialogVisible3 = false
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }