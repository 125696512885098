var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "table-container",
        style: { height: _vm.height + "px", overflow: "scroll" },
      },
      [
        _c("el-button", { attrs: { type: "text" } }, [_vm._v("面诊方案：")]),
        _vm._v(" "),
        _c("VxeTable", {
          ref: "table",
          attrs: {
            temp: _vm.customer,
            list: _vm.list2,
            height: _vm.height - 50,
            "list-query": _vm.listQuery,
            columns: _vm.columns2,
            checkbox: "true",
            selectInit: _vm.selectInit,
          },
          on: {
            dbclick: _vm.handleClick,
            selectItem: _vm.selectItem,
            allSelect: _vm.allSelect,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }