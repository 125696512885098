import request from '@/utils/request'; // 项目列表分页

export function projectPage(data) {
  return request({
    url: '/manager/project/page',
    method: 'post',
    data: data
  });
} // 项目列表不分页

export function projectList(data) {
  return request({
    url: '/manager/project/list',
    method: 'post',
    data: data
  });
} // 套餐列表分页

export function productToolPage(data) {
  return request({
    url: '/manager/producttool/page',
    method: 'post',
    data: data
  });
} //

export function findPdutoDetByPthCode(data) {
  return request({
    url: '/manager/pdutol/findPdutoDetByPthCode',
    method: 'get',
    params: data
  });
} //生成项目编码

export function createProjectCode(data) {
  return request({
    url: '/manager/project/genCode',
    method: 'get',
    params: data
  });
} //新增编辑项目

export function addProject(data) {
  return request({
    url: '/manager/project/addOrUpdate',
    method: 'post',
    data: data
  });
} //删除项目

export function delProject(data) {
  return request({
    url: '/manager/project/delete',
    method: 'post',
    params: data
  });
} //新增编辑套餐

export function addTc(data) {
  return request({
    url: '/manager/producttool/addOrUpdate',
    method: 'post',
    data: data
  });
} //生成套餐编码

export function createTCCode(data) {
  return request({
    url: '/manager/producttool/genCode',
    method: 'get',
    params: data
  });
} //删除套餐

export function delTc(data) {
  return request({
    url: '/manager/producttool/delete',
    method: 'POST',
    params: data
  });
} //删除套餐项目

export function delTcPro(data) {
  return request({
    url: '/manager/producttool/det/delete',
    method: 'post',
    params: data
  });
} //为套餐增加编辑项目

export function proToTc(data) {
  return request({
    url: '/manager/producttool/det/addOrUpdate',
    method: 'post',
    data: data
  });
} // 项目定金统计-按期

export function userBalance(data) {
  return request({
    url: '/manager/report/userBalance/queryScheduleLadder',
    method: 'post',
    data: data
  });
} //项目定金统计-按年

export function queryYearStatistics(data) {
  return request({
    url: '/manager/report/userBalance/queryYearStatistics',
    method: 'post',
    data: data
  });
} //适应症一级

export function symptomPage(data) {
  return request({
    url: '/manager/symptom/page',
    method: 'post',
    data: data
  });
} //批量新增体验项目

export function genTyProject(data) {
  return request({
    url: '/manager/project/genTyProject',
    method: 'post',
    data: data
  });
} //批量新增体验项目 校验

export function valiadTyProject(data) {
  return request({
    url: '/manager/project/valiadTyProject',
    method: 'post',
    data: data
  });
} //优惠列表

export function selectActivityZpt(data) {
  return request({
    url: '/manager/activity/rule/selectActivityZpt',
    method: 'post',
    data: data
  });
} //获取推广标签

export function genLabelList(data) {
  return request({
    url: '/manager/label/page',
    method: 'post',
    data: data
  });
}
export function getpduhc(data) {
  return request({
    url: '/manager/project/getpduhc',
    method: 'get',
    params: data
  });
}
export function addOrUpdatePduHc(data) {
  return request({
    url: '/manager/project/addOrUpdatePduHc',
    method: 'post',
    data: data
  });
}
export function delPduHc(data) {
  return request({
    url: '/manager/project/delPduHc',
    method: 'get',
    params: data
  });
} // 项目导出

export function projectExport(data) {
  return request({
    url: '/manager/project/pageExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // (日常套餐列表&促销套餐列表&历史促销套餐列表)项目导出

export function producttoolExportExcel(data) {
  return request({
    url: '/manager/producttool/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 新增编辑删除项目权限接口

export function projectAuthSaveOrUpdate(data) {
  return request({
    url: '/manager/project/auth/saveOrUpdate',
    method: 'get',
    params: data
  });
} // 新增编辑删除项目权限接口

export function projectAuthGetZptCodeAuth(data) {
  return request({
    url: '/manager/project/auth/getZptCodeAuth',
    method: 'get',
    params: data
  });
}
export function getRfmAuth(data) {
  return request({
    url: '/manager/producttool/getRfmAuth',
    method: 'get',
    params: data
  });
}
export function addRfmAuth(data) {
  return request({
    url: '/manager/producttool/addRfmAuth',
    method: 'get',
    params: data
  });
} // 新增编辑删除项目权限接口

export function projectAuthBatchSaveOrUpdate(data) {
  return request({
    url: '/manager/project/auth/batchSaveOrUpdate',
    method: 'post',
    data: data
  });
} // 获取 项目 列表 反选

export function getProjectCodeList(data) {
  return request({
    url: "/manager/project/getProjectCodeList",
    method: 'post',
    data: data
  });
} // 获取  产品 列表 反选

export function getStockActList(data) {
  return request({
    url: "/manager/product/getMaterialCodeList",
    method: 'post',
    data: data
  });
} // 获取 套餐列表 反选

export function getActList(data) {
  return request({
    url: "/manager/producttool/getActList",
    method: 'post',
    data: data
  });
}