var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", style: { height: _vm.height - 30 + "px" } },
    [
      _c(
        "el-container",
        { staticStyle: { "flex-direction": "column" } },
        [
          _c(
            "el-main",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleTabClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "客户资料", name: "1" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "过往咨询", name: "2" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "客户交易", name: "3" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "回访管理", name: "4" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "面部评估", name: "5" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { style: { height: _vm.height / 2 + 270 + "px" } },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "830px" } },
                    [
                      _c("kh-form", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName === "1",
                            expression: "activeName === '1'",
                          },
                        ],
                        ref: "khForm",
                        attrs: {
                          id: _vm.customer.customerId,
                          caltypeList: _vm.caltypeList,
                          customer: _vm.customer,
                          importList: _vm.importList2,
                          "ctf-zd-doc-list": _vm.employees,
                          levelList: _vm.levelList2,
                          height: _vm.height / 2 + 270,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.activeName == "2"
                    ? _c("gw-table", {
                        ref: "gwTable",
                        attrs: {
                          active: _vm.activeName == "2" ? "gwTable" : "",
                          customer: _vm.customer,
                          "ctf-zd-doc-list": _vm.employees,
                          height: _vm.innerHeight / 2 + 200,
                          rvGwType: "",
                          autoResize: true,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("xf-table", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeName == "3",
                        expression: "activeName == '3'",
                      },
                    ],
                    ref: "xfTable",
                    attrs: {
                      customer: _vm.customer,
                      "ctf-zd-doc-list": _vm.employees,
                      height: _vm.innerHeight / 2 + 200,
                    },
                  }),
                  _vm._v(" "),
                  _vm.activeName == "4"
                    ? _c("rv-table", {
                        ref: "rvTable",
                        attrs: {
                          customer: _vm.customer,
                          "ctf-zd-doc-list": _vm.employees,
                          active: _vm.activeName == "4" ? "rvTable" : "",
                          showSaveBtn: true,
                          height: _vm.innerHeight / 2 + 50,
                          autoResize: true,
                          onlyRead: true,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName == "5"
                    ? _c("mbpg-table", {
                        ref: "rvTable",
                        attrs: {
                          customer: _vm.customer,
                          "ctf-zd-doc-list": _vm.employees,
                          showSaveBtn: true,
                          height: _vm.innerHeight / 2 + 50,
                          autoResize: true,
                          onlyRead: true,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-main"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }