import request from '@/utils/request'; //获取价格pdf

export function getPricePdf() {
  return request({
    url: '/manager/yblkey/getPricePdf',
    method: 'get'
  });
} //给按钮设置权限

export function saveOrUpdateAuth(params) {
  return request({
    url: '/manager/btnAuth/saveOrUpdateAuth',
    method: 'get',
    params: params
  });
}
export function getPricePdfList(params) {
  return request({
    url: '/manager/yblkey/getPricePdfList',
    method: 'get',
    params: params
  });
}
export function deletePricePdf(params) {
  return request({
    url: '/manager/yblkey/delete',
    method: 'get',
    params: params
  });
} //获取按钮配置的权限

export function getBtnAuth(params) {
  return request({
    url: '/manager/btnAuth/getBtnAuth',
    method: 'get',
    params: params
  });
} //保存PDF url

export function addPricePdf(params) {
  return request({
    url: '/manager/yblkey/addPricePdf',
    method: 'get',
    params: params
  });
} //优惠后台 列表

export function activityPage(data) {
  return request({
    url: '/manager/activity/rule/getList',
    method: 'post',
    data: data
  });
} //优惠后台 新增

export function activityAdd(data) {
  return request({
    url: '/manager/activity/rule/add',
    method: 'post',
    data: data
  });
} //优惠后台 批量新增

export function addByldMuti(data) {
  return request({
    url: '/manager/activity/rule/addMuti',
    method: 'post',
    data: data
  });
} //优惠后台 删除

export function activityDel(params) {
  return request({
    url: '/manager/activity/rule/del',
    method: 'post',
    params: params
  });
} //优惠后台 编辑

export function activityEditById(data) {
  return request({
    url: '/manager/activity/rule/editById',
    method: 'post',
    data: data
  });
} //优惠后台 批量编辑

export function editByIdMuti(data) {
  return request({
    url: '/manager/activity/rule/editByIdMuti',
    method: 'post',
    data: data
  });
}