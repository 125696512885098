import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { rvList, delRv, rvBatchAdd } from '@/api/returnVisit'; // import EditTable from '../components/EditTable';

import JhTable from "./jh-table";
import { mapGetters } from 'vuex';
import moment from 'moment';
import VxeTable from "./VxeTable.vue";
import { Message } from 'element-ui';
export default {
  name: 'RvTable',
  components: {
    JhTable: JhTable,
    VxeTable: VxeTable
  },
  props: {
    customer: {
      required: true,
      type: Object
    },
    ctfZdDocList: {
      required: true,
      type: Array
    },
    height: {
      required: true
    },
    showSaveBtn: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    delDisaled: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    autoResize: {
      type: Boolean
    },
    onlyRead: {
      type: Boolean,
      default: false
    },
    pageType: {
      type: Number
    },
    comPage: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      list: [],
      changeList: [],
      listLoading: false,
      dialogVisible: false,
      cur: undefined,
      total: 0,
      temp: {},
      customerMes: {},
      columns: [],
      checked: false,
      saveBtnDisabled: false,
      stateList: [{
        title: '接通',
        field: '接通'
      }, {
        title: '关机',
        field: '关机'
      }, {
        title: '无人接听',
        field: '无人接听'
      }, {
        title: '停机',
        field: '停机'
      }, {
        title: '重打',
        field: '重打'
      }, {
        title: '拒接',
        field: '拒接'
      }, {
        title: '空号',
        field: '空号'
      }, {
        title: '有效',
        field: '有效'
      }],
      lostReasonList: [{
        title: '考虑',
        field: '考虑'
      }, {
        title: '时间',
        field: '时间'
      }, {
        title: '转医院',
        field: '转医院'
      }, {
        title: '价格',
        field: '价格'
      }, {
        title: '安全',
        field: '安全'
      }, {
        title: '不考虑',
        field: '不考虑'
      }, {
        title: '对比',
        field: '对比'
      }, {
        title: '预约',
        field: '预约'
      }],
      listQuery: {
        pageNum: 1,
        pageSize: 50
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          var nowData = new Date();
          nowData = new Date(nowData.setDate(nowData.getDate() - 1));
          return time < nowData;
        }
      },
      copyCustomer: {}
    };
  },
  watch: {
    checked: function checked(val) {
      this.listQuery.rviEmp = val ? this.empCode : undefined;
      this.getList(this.customerMes);
    },
    customer: function customer(newVal, oldVal) {
      if (newVal.id != oldVal.id) {
        this.copyCustomer = newVal;
        this.getList(newVal);
      }
    }
  },
  computed: _objectSpread({}, mapGetters(['earId', 'empCode', 'rvTypes', 'employees', 'employeesAll', 'intensList', 'userInfo', 'roleId'])),
  created: function created() {
    this.innerHeight = $(window).height() - 50;
    this.columns = [{
      title: '登记日期',
      field: 'rviDate',
      type: 'input',
      fixed: 'left',
      canEdit: function canEdit() {
        return false;
      },
      width: '95px'
    }, {
      title: '回访电话',
      field: 'rviTel',
      type: 'input',
      width: '120px',
      required: true,
      fixed: 'left',
      input: this.rvChange,
      canEdit: this.handCanEdit
    }, {
      title: '计划日期',
      field: 'rviNext',
      type: 'date',
      fixed: 'left',
      required: true,
      width: '95px',
      pickerOptions: this.pickerOptions,
      canEdit: this.handCanEdit,
      change: this.rvChange
    }, {
      title: '回访人员',
      field: 'rviEmp',
      type: 'select',
      required: true,
      canEdit: this.handCanEdit,
      width: this.autoResize ? '120px' : '80px',
      searchList: this.getEmployeeTypeList(),
      searchList1: this.employeesAll,
      formatter: this.getColumnValue,
      change: this.rvChange
    }, {
      title: '回访类型',
      field: 'rviType',
      type: 'select',
      required: true,
      canEdit: this.handCanEdit,
      width: '80px',
      searchList: this.rvTypes,
      formatter: this.getColumnValue,
      change: this.rvChange
    }, {
      title: '回访计划',
      field: 'rviTitle',
      type: 'input',
      required: false,
      canEdit: this.handCanEdit,
      input: this.rvChange,
      width: '100px'
    }, {
      title: '回访情况',
      field: 'rviInfo',
      type: 'btnTextarea',
      required: false,
      canEdit: this.handCanEdit,
      hideBtn: true,
      width: this.autoResize ? '250px' : '180px',
      input: this.rvChange,
      trigger: 'hover'
    }, {
      title: '潜在需求3',
      field: 'rviQzInfo',
      type: 'select',
      required: false,
      canEdit: this.handCanEdit,
      width: '100px',
      desc: true,
      searchList: this.intensList,
      formatter: this.getColumnValue,
      change: this.rvChange
    }, {
      title: '执行人',
      field: 'rviCfmemp',
      type: 'select',
      required: false,
      canEdit: this.handCanEdit,
      width: this.autoResize ? 'auto' : '80px',
      searchList: this.getEmployeeTypeList(),
      formatter: this.getColumnValue,
      change: this.rvChange,
      searchList1: this.employeesAll
    }, {
      title: '执行时间',
      field: 'rviTime',
      type: 'text',
      width: this.autoResize ? 'auto' : '80px',
      canEdit: this.handCanEdit
    }, {
      title: '状态',
      field: 'rviStatus',
      type: 'select',
      canEdit: this.handCanEdit,
      width: this.autoResize ? 'auto' : '80px',
      searchList: this.stateList,
      formatter: this.getColumnValue,
      change: this.rvChange
    }, {
      title: '操作员',
      field: 'rviOpter2',
      type: 'select',
      canEdit: false,
      width: this.autoResize ? 'auto' : '80px',
      searchList: this.getEmployeeTypeList(),
      searchList1: this.employeesAll,
      formatter: this.getColumnValue
    }];
  },
  methods: {
    getColumnValue: function getColumnValue(value, list) {
      var doc = {
        title: ''
      };

      if (list) {
        list.map(function (item) {
          if (item.field == value) {
            doc = item;
          }
        });
      }

      return doc.title;
    },
    getList: function getList(customer) {
      var _this = this;

      this.list = [];

      if (customer) {
        this.customerMes = JSON.parse(JSON.stringify(customer));
        this.listQuery.ctmId = customer.ctmCode || customer.ctfCtmcode || customer.pidCtmcode || customer.cpyCtmcode;
        this.listQuery.ctmCode = customer.ctfCtmcode || customer.ctmCode;

        if (!this.listQuery.ctmId) {
          return;
        }

        this.listLoading = true;
        this.$toggleLoading();
        rvList(this.listQuery).then(function (response) {
          _this.listLoading = false;
          var rvString = ['rviTel', 'rviNext', 'rviEmp', 'rviType', 'rviTitle', 'rviInfo', 'rviQzInfo', 'rviCfmemp', 'rviStatus'];

          if (response.data && response.data.list) {
            _this.list = response.data.list;

            var _loop = function _loop(i) {
              // var item = response.data.list[i]
              _this.list[i].rviDate = _this.list[i].rviDate.substring(0, 10);
              _this.list[i].rviNext = moment(_this.list[i].rviNext).format('YYYY-MM-DD');
              var diff2day = false;

              if (_this.list[i].rviTime) {
                var day = moment().format("YYYY-MM-DD");
                var before1day = moment(_this.list[i].rviTime).add(-1, 'd').format("YYYY-MM-DD");
                var diff = moment(before1day, "YYYY-MM-DD").diff(moment(day, "YYYY-MM-DD"), "day");

                if (diff >= -1) {
                  //2天内的数据可以改
                  diff2day = true;
                }
              }

              if (_this.list[i].rviEmp === _this.empCode && !_this.list[i].rviTime || _this.list[i].rviEmp === _this.empCode && diff2day || _this.userInfo.GLOBAL_RIGHT) {
                rvString.forEach(function (v) {
                  var str = v + 'edit'; // console.log('this.list[i][v]',this.list[i][v]);
                  // this.list[i][v] ? null : (this.list[i][str] = true);

                  _this.list[i][str] = true; // console.log('rviType',this.list[i]['rviType']);

                  if (_this.list[i]['rviType'] === '其它') {
                    _this.$set(_this.list[i], 'rviTypeedit', true);
                  }

                  if (_this.userInfo.GLOBAL_RIGHT) {
                    _this.list[i][str] = true;
                  }
                });
              } // console.log('item', item);
              // this.list.push(item)

            };

            for (var i in _this.list) {
              _loop(i);
            }

            _this.total = response.data.total;

            _this.$toggleLoading();
          } else {
            _this.list = [];

            _this.$toggleLoading();
          }
        }).finally(function () {// this.$toggleLoading()
        });
      }
    },
    handleClick: function handleClick(row) {
      this.temp = row;
    },
    handleAdd: function handleAdd() {
      this.temp = {
        rviDate: moment().format('YYYY-MM-DD'),
        rviTel: this.customer.ctfTel || this.customer.rviTel || this.customer.pidCtmTel || this.customer.ctmMobile,
        rviEmp: this.roleId === 6 ? this.customer.ctfEmpCode : this.empCode,
        ctmCode: this.customer.ctmCode || this.customer.ctfCtmcode || this.customer.pidCtmcode || this.customer.cpyCtmcode,
        ctmName: this.customer.ctmName || this.customer.ctfCtmname || this.customer.pidCtmname,
        rviNext: null,
        rviType: null,
        rviTitle: null,
        rviTitleedit: true,
        rviInfo: null,
        rviQzInfo: null,
        change: true,
        pageType: this.pageType
      };
      this.list.unshift(this.temp);
    },
    handlePlan: function handlePlan() {
      this.dialogVisible = true;
    },
    // infoEdit(col,val) {
    //   if (val.id && val.rviInfo) {
    //     return false
    //   } else {
    //     return true
    //   }
    // },
    handleDel: function handleDel() {
      var _this2 = this;

      console.log('this.temp', this.temp);

      if (this.temp.id) {
        if (this.userInfo.GLOBAL_RIGHT) {
          delRv({
            rvId: this.temp.id
          }).then(function (response) {
            if (response && response.code == 200) {
              // this.list.splice(this.cur, 1)
              _this2.getList(_this2.customerMes);
            }
          });
        }
      } else {
        var index = this.temp.index;

        if (!this.list[index].id) {
          this.list.splice(index, 1);
        }
      }
    },
    handleSave: function handleSave() {
      var _this3 = this;

      var list = JSON.parse(JSON.stringify(this.list)); // 过滤新增的数据

      var addDatas = list.filter(function (v) {
        return v.change;
      }); // console.log('addDatas', addDatas)
      // console.log('addDatas', this.changeList)
      // addDatas.push(...this.changeList);

      if (!this.$refs.table.checkColumns(addDatas)) {
        return;
      }

      if (addDatas.length > 0) {
        for (var i in addDatas) {
          addDatas[i].rviQzInfo = addDatas[i].rviQzInfo ? addDatas[i].rviQzInfo + '' : null;
          addDatas[i].rviQzInfo2 = addDatas[i].rviQzInfo2 ? addDatas[i].rviQzInfo2 + '' : null;
          addDatas[i].rviNext = moment(addDatas[i].rviNext).format('YYYY-MM-DD HH:mm:ss');

          if (this.comPage) {
            addDatas[i].pageType = 1;
          }
        }

        rvBatchAdd(addDatas).then(function (response) {
          _this3.saveBtnDisabled = true;
          _this3.changeList = [];

          _this3.getList(_this3.customerMes);

          if (response && response.code === 200) {
            Message({
              message: '保存成功',
              type: 'success',
              onClose: function onClose() {}
            });
          }
        }).finally(function () {
          _this3.saveBtnDisabled = false;
        });
      }
    },
    handleCancel: function handleCancel() {
      this.dialogVisible = false;
    },
    rvChange: function rvChange(row, field) {
      if (row.id) {
        if (!row.rviCfmemp && !row.rviTime && field === 'rviInfo') {
          if (row.rviInfo) {
            this.$set(row, 'rviCfmemp', this.empCode);
            this.$set(row, 'rviTime', moment().format('YYYY-MM-DD HH:mm:ss'));
          }
        } else if (!row.rviInfo) {
          this.$set(row, 'rviCfmemp', null);
          this.$set(row, 'rviTime', null);
        }

        this.list[row.index].change = true;
      }
    },
    handleDone: function handleDone(list) {
      this.dialogVisible = false;

      for (var i in list) {
        var item = {};
        item.rviDate = moment().format('YYYY-MM-DD');
        item.rviTel = this.customer.ctfTel || this.customer.rviTel || this.customer.pidCtmTel || this.customer.ctmMobile;
        item.rviEmp = this.roleId === 6 ? this.customer.ctfEmpCode : this.empCode;
        item.rviType = list[i].ctnRvtype;
        item.rviTitle = list[i].ctnRemark;
        item.change = true;
        item.pageType = this.pageType;
        item.ctmCode = this.customer.ctmCode || this.customer.ctfCtmcode || this.customer.pidCtmcode;
        item.ctmName = this.customer.ctmName || this.customer.ctfCtmname || this.customer.pidCtmname;
        item.rviNext = moment().add(list[i].ctnDays, 'day').format('YYYY-MM-DD');
        this.list.unshift(item);
      }
    },
    handlePlay: function handlePlay() {},
    handlStop: function handlStop() {},
    handCanEdit: function handCanEdit(row, column) {
      if (this.userInfo.GLOBAL_RIGHT) return true;

      if (!row.id || row[column.field + 'edit']) {
        return true;
      } else {
        return false;
      }
    }
  }
};