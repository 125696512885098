import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { customerSave, getSmTimes, getCustomerDetail } from '@/api/customer';
import { mapGetters } from 'vuex';
import { Message } from 'element-ui';
import { formatValue } from '@/utils';
import BaseForm from "../components/BaseForm";
import baseFormMixin from "./formMixin";
export default {
  name: 'KhForm',
  mixins: [baseFormMixin],
  components: {
    BaseForm: BaseForm
  },
  props: {
    id: {
      required: true,
      type: Number
    },
    ctfZdDocList: {
      required: true,
      type: Array
    },
    levelList: {
      required: true,
      type: Array
    },
    importList: {
      required: true,
      type: Array
    },
    height: {
      required: true
    },
    // 是否禁用表单
    formDisabled: {
      default: false
    },
    customer2: {
      type: Object
    },
    unitDisabled: {
      type: Boolean
    },
    customer_1: {
      type: Object
    }
  },
  data: function data() {
    return {
      addMessage: '',
      flag2: false,
      flag1: false,
      isChange: false,
      activeList: [],
      loading: false,
      customer: {},
      caltypeList2: [],
      customerList: [],
      ctfPtypeList: [],
      statusList: [],
      ctfZdDocList2: [],
      ctfZdDocList3: [],
      dialogVisible3: false,
      times: {
        startDate: '',
        endDate: '',
        time: '',
        time1: 0,
        time2: 0,
        time3: 0,
        time4: 0,
        otherTime: 0,
        monthNum: 0
      },
      timeList: [{
        title: '1个月内',
        field: '1'
      }, {
        title: '2个月内',
        field: '2'
      }, {
        title: '3个月内',
        field: '3'
      }, {
        title: '4个月内',
        field: '4'
      }, {
        title: '5个月内',
        field: '5'
      }, {
        title: '6个月内',
        field: '6'
      }, {
        title: '7个月内',
        field: '7'
      }, {
        title: '8个月内',
        field: '8'
      }, {
        title: '9个月内',
        field: '9'
      }, {
        title: '10个月内',
        field: '10'
      }, {
        title: '11个月内',
        field: '11'
      }, {
        title: '12个月内',
        field: '12'
      }],
      deleteLevel: {},
      disabledType: false,
      rules: {
        ctmName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        ctmMobile: [{
          required: true,
          message: '请输入电话号码',
          trigger: 'blur'
        }],
        eptPid: [{
          required: true,
          message: '请选择信息来源',
          trigger: 'blur'
        }],
        ctfPtype: [{
          required: true,
          message: '请输入电话号码',
          trigger: 'blur'
        }],
        eptId: [{
          required: true,
          trigger: 'blur'
        }],
        ctfJsCtmcode: [{
          required: true,
          trigger: 'blur'
        }]
      },
      ygPopList: [{
        slot: 'ssgw',
        ref: 'closepopoverGw2',
        positionType: 'ssgw',
        title: '所属顾问',
        name: 'ctfEmpName',
        code: 'ctfEmpCode',
        disabledStr: 'ctfEmpName'
      }, {
        slot: 'ctfNurseName',
        ref: 'closepopoverNs',
        positionType: 'sshls',
        title: '所属护理师',
        name: 'ctfNurseName',
        code: 'ctfNurse',
        disabledStr: 'ctfNurseCodeName'
      }, {
        slot: 'ctfBeauticianName',
        ref: 'closepopoverBt',
        positionType: 'ssmrs',
        title: '所属美容师',
        name: 'ctfBeauticianName',
        code: 'ctfBeautician',
        disabledStr: 'ctfBeaut'
      }, {
        slot: 'ctmDocName',
        ref: 'closepopoverDoc',
        positionType: 'zzys',
        title: '所属医生',
        name: 'ctmDocName',
        code: 'ctmDoc',
        disabledStr: function disabledStr() {
          return true;
        }
      }, {
        slot: 'kf',
        ref: 'closepopoverKf',
        positionType: 'kf',
        title: '开发人员',
        name: 'kfPeopleName',
        code: 'kfPeople',
        disabledStr: 'kfPeopleN'
      }, {
        slot: 'active',
        ref: 'closepopoverKf',
        positionType: 'kf',
        title: '激活人',
        name: 'activOp',
        code: 'activOpCode'
      }]
    };
  },
  watch: {
    customer: {
      handler: function handler(val) {
        // this.customer.ctmIfbirth = this.customer.ctmIfbirth === 'A';
        this.$set(val, 'activOp', val.activOpName);
      }
    },
    customer2: {
      handler: function handler(val) {
        this.disabledType = val.levelManuleFlag != 1;
      }
    } // id: {
    //   handler: async function(id) {
    //     this.customer = {};
    //     if (id) {
    //       this.isChange = true;
    //       await this.getCtmDetail(id);
    //     }
    //   },
    //   immediate: true,
    // },
    // 当有传进来caltypeList时，赋值给caltypeList

  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['earId', 'departmentList', 'intensList', 'eareList', 'userInfo', 'caltypeList', 'simpleCalType', 'empSelectSaveStatus', 'vipLevelList', 'empCode'])), {}, {
    disabledFuc: function disabledFuc() {
      if (this.userInfo.GLOBAL_RIGHT) {
        return false;
      } else {
        return true;
      }
    }
  }),
  created: function created() {
    if (!this.caltypeList) {
      this.$refs.baseForm.getCaltypeList();
    }

    if (this.ctfZdDocList.length > 0) {
      this.ctfZdDocList2 = JSON.parse(JSON.stringify(this.ctfZdDocList));
    }
  },
  methods: {
    getSmTimesList2: function getSmTimesList2() {
      var _this = this;

      this.times.ctmCode = this.customer.ctmCode;
      getSmTimes(this.times).then(function (res) {
        if (res && res.code == 200) {
          _this.times.otherTime = res.data;
          Message({
            message: '获取上门次数成功',
            type: 'success',
            onClose: function onClose() {
              _this.flag1 = false;
              _this.flag2 = false;
            }
          });
          _this.dialogVisible3 = false;
        }
      });
    },
    getTimes: function getTimes() {
      this.times.monthNum = 0;
      this.flag2 = false;
      this.flag1 = false;
      this.dialogVisible3 = true;
    },
    handleSave: function handleSave(clearJs) {
      var _this2 = this;

      var customer = clearJs ? this.customer_1 : this.customer;
      customer.ctmStatus = null;
      customer.ctfTel = customer.ctmMobile;
      customer.dkStatus = customer.dkStatus ? 'Y' : 'N';
      customer.ctfFucstatus = customer.ctfFucstatus ? 'Y' : 'N';
      customer.ctmIfbirth = customer.ctmIfbirth ? 'A' : 'B';
      customer.visitTimesList = [this.times.time1, this.times.time2, this.times.time3, this.times.time4];
      customer.payAmtList = [customer.payAmtList0 || 0, customer.payAmtList1 || 0, customer.payAmtList2 || 0, customer.payAmtList3 || 0, customer.payAmtList4 || 0, customer.payAmtList5 || 0];
      var Pid = customer.eptPid === 5;

      if (clearJs) {
        if (Pid && customer.id) {
          this.customer.eptId = 31;
          this.customer_1.eptId = 31;
          customer.eptId = 31;
          customer.isHh = null;
          customer.eptName = '老带新-口碑';
          this.customer.eptCode = 'LDX3';
          this.customer_1.eptCode = 'LDX3';
          customer.eptCode = 'LDX3';
          this.customer.eptName = '口碑';
          this.customer_1.eptName = '口碑';
          customer.eptName = '口碑';
          this.save(customer, clearJs);
        }

        return;
      }

      if (customer.eptPid == 5 && (customer.eptId == 29 || customer.eptId == 30) && !customer.ctfJsCtmcode) {
        Message({
          message: '请选择介绍人',
          type: 'warning'
        });
      }

      if (this.empSelectSaveStatus) {
        var _customer = _customer;
        var msg = '';
        this.ygPopList.forEach(function (item) {
          if (_customer[item.name] && _customer[item.code] === '') {
            msg = item.title;
            return;
          }
        });
        this.$message({
          type: 'warning',
          message: "\u8BF7\u53CC\u51FB\u9009\u62E9".concat(msg, "\uFF01"),
          duration: 1500
        });

        if (msg) {
          return;
        }
      }

      var valid = this.$refs.baseForm.valid();
      valid.then(function (res) {
        if (res) {
          _this2.save(customer);
        }
      });
    },
    save: function save(customer, clearJs) {
      var _this3 = this;

      if (clearJs) {
        customer.ctfJsPeople = '';
        customer.ctfJsCtmPhone = '';
        customer.ctfJsCtmcode = '';
        this.$emit('done', customer);
        return;
      }

      customerSave(customer).then(function (response) {
        if (response.code == 200) if (!clearJs) {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 500,
            onClose: function onClose() {
              _this3.$emit('done');
            }
          });
        } else {
          _this3.$emit('done', customer);
        }
      });
    },
    getCtmDetail: function getCtmDetail(id) {
      var _this4 = this;

      if (!id) return;
      var vm = this;
      vm.loading = true;
      this.customer = {};
      getCustomerDetail({
        id: id
      }).then(function (response) {
        var list = response.data;
        var payAmtList = list.payAmtList,
            visitTimesList = list.visitTimesList;

        if (Array.isArray(payAmtList)) {
          payAmtList.forEach(function (ele, index) {
            list["payAmtList".concat(index)] = ele;
          });
        }

        if (Array.isArray(visitTimesList)) {
          visitTimesList.forEach(function (ele, index) {
            _this4.times["time".concat(index + 1)] = ele;
          });
        }

        _this4.$set(list, 'tempPId', list.eptPid);

        _this4.addMessage = "".concat(formatValue(vm.levelList, list.ctmLevel), "-").concat(list.importantLevel, "-").concat(formatValue(vm.importList, list.importantLevel));
        _this4.customer = list;
        _this4.customer.empcodeName = _this4.customer.ctfEmpName;
        _this4.customer.ctfNurseCodeName = _this4.customer.ctfNurseName;
        _this4.customer.ctfBeaut = _this4.customer.ctfBeauticianName;
        _this4.customer.kfPeopleN = _this4.customer.kfPeople;
        _this4.customer.ctmDocDis = _this4.customer.ctmDoc;
        _this4.customer.ctmIfbirth = _this4.customer.ctmIfbirth === 'A';

        _this4.$refs.baseForm.getCaltypeList(list.eptPid);

        _this4.isChange = false;
        vm.loading = false;
      });
    }
  }
};