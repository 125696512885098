var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fixed_table", style: { height: _vm.height + "px" } },
    [
      _c(
        "div",
        {
          staticClass: "table-container",
          style: { height: _vm.height + "px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-tabs",
                    {
                      on: {
                        "tab-click": function (e) {
                          _vm.listQuery.pageNum = 1
                          _vm.tabChange(e)
                        },
                      },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      !_vm.showCash
                        ? _c("el-tab-pane", {
                            attrs: { label: "剩余项目列表", name: "1" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showCash
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "", name: "2" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _vm._v(
                                    "\n              客户已消费列表\n              "
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "70px" },
                                      attrs: {
                                        placeholder: "(项目+产品)",
                                        size: "mini",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.tabChange({
                                            name: _vm.activeName,
                                          })
                                        },
                                      },
                                      model: {
                                        value: _vm.value,
                                        callback: function ($$v) {
                                          _vm.value = $$v
                                        },
                                        expression: "value",
                                      },
                                    },
                                    _vm._l(_vm.options, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showCash
                        ? _c("el-tab-pane", { attrs: { name: "3" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _vm._v(
                                  "\n              客户已消耗列表\n              "
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "70px" },
                                    attrs: {
                                      placeholder: "(项目+产品)",
                                      size: "mini",
                                      clearable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.tabChange({
                                          name: _vm.activeName,
                                        })
                                      },
                                    },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  },
                                  _vm._l(_vm.options, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "客户收费管理", name: "4" } },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v(
                                "\n              客户收费管理\n              "
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "50px" },
                                  attrs: {
                                    placeholder: "",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.tabChange({
                                        name: _vm.activeName,
                                      })
                                    },
                                  },
                                  model: {
                                    value: _vm.value2,
                                    callback: function ($$v) {
                                      _vm.value2 = $$v
                                    },
                                    expression: "value2",
                                  },
                                },
                                _vm._l(_vm.options2, function (item) {
                                  return _c("el-option", {
                                    key: item.value2,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("pagination", {
                    attrs: {
                      pageSizes: _vm.pageSizes,
                      total: _vm.total,
                      layout: "total,prev,next",
                      page: _vm.listQuery.pageNum,
                      limit: _vm.listQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageSize", $event)
                      },
                      pagination: function ($event) {
                        return _vm.tabChange({ name: _vm.activeName })
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.showCash
            ? [
                _c("VxeTable", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeName == "1",
                      expression: "activeName == '1'",
                    },
                  ],
                  ref: "table1",
                  attrs: {
                    temp: _vm.customer1,
                    list: _vm.list1,
                    total: _vm.total,
                    height: _vm.height - 170,
                    "list-loading": _vm.listLoading,
                    "list-query": _vm.listQuery,
                    "row-key": "id",
                    columns: _vm.columns1,
                    "row-class-name": _vm.tableRowClassName,
                    scroll: { x: 8, y: 15 },
                    scrollTopLastLocation: false,
                    autoClear: false,
                  },
                  on: { pagination: _vm.getList1, click: _vm.handleClick },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "ctpFnsdate",
                        fn: function (ref) {
                          var column = ref.column
                          var row = ref.row
                          return [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  placement: "bottom",
                                  "popper-class": "time_tip",
                                },
                              },
                              [
                                _c("div", {
                                  attrs: { slot: "content" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.showContent),
                                  },
                                  slot: "content",
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    ref: "dcPopover",
                                    attrs: {
                                      placement: "bottom",
                                      width: "600",
                                      trigger: "click",
                                      "popper-options": {
                                        boundariesElement: "viewport",
                                        removeOnDestroy: true,
                                      },
                                    },
                                    on: {
                                      show: function ($event) {
                                        _vm.showPopTime = true
                                      },
                                      hide: function ($event) {
                                        _vm.showPopTime = false
                                      },
                                    },
                                  },
                                  [
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "form",
                                          attrs: {
                                            inline: true,
                                            model: _vm.form,
                                            size: "mini",
                                            "label-position": "left",
                                            "label-width": "80px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "截止时间:" } },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "60%" },
                                                attrs: {
                                                  type: "date",
                                                  placeholder: "",
                                                  format: "yyyy-MM-dd",
                                                  "value-format": "yyyy-MM-dd",
                                                  clearable: "",
                                                  "picker-options":
                                                    column.pickerOptions,
                                                },
                                                model: {
                                                  value: _vm.form.ctpFnsdate2,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "ctpFnsdate2",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.ctpFnsdate2",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "success",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modifyTime(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("确定")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.mapFnsList[row.id],
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "flex",
                                              staticStyle: { margin: "5px 0" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "flex-1",
                                                  staticStyle: {
                                                    display: "block",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "操作人: " +
                                                      _vm._s(item.opter) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "flex-1",
                                                  staticStyle: {
                                                    display: "block",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "修改时间: " +
                                                      _vm._s(item.updateTime) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "flex-1",
                                                  staticStyle: {
                                                    display: "block",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "截止时间: " +
                                                      _vm._s(item.updateAfter) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "el-button v_button ",
                                        attrs: {
                                          slot: "reference",
                                          icon: "el-icon-caret-bottom",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.btnClick(row)
                                          },
                                          mouseover: function ($event) {
                                            return _vm.getFnsList(row)
                                          },
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-caret-bottom",
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row[column.field]) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1748908288
                  ),
                }),
                _vm._v(" "),
                _c("VxeTable", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeName == "2" && _vm.showCustoomerUseList,
                      expression: "activeName == '2' && showCustoomerUseList",
                    },
                  ],
                  ref: "table2",
                  attrs: {
                    temp: _vm.customer,
                    list: _vm.list2,
                    height: _vm.height - 170,
                    "list-loading": _vm.listLoading,
                    "list-query": _vm.listQuery,
                    columns: _vm.columns2,
                    scroll: { x: 8, y: 15 },
                    scrollTopLastLocation: false,
                    autoClear: false,
                  },
                  on: { click: _vm.handleClick },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "ctpFnsdate",
                        fn: function (ref) {
                          var column = ref.column
                          var row = ref.row
                          return [
                            _c(
                              "el-popover",
                              {
                                ref: "dcPopover",
                                attrs: {
                                  placement: "bottom",
                                  width: "570",
                                  trigger: "hover",
                                },
                              },
                              [
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.showContent) +
                                      "\n            "
                                  ),
                                ],
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-button v_button ",
                                    attrs: {
                                      slot: "reference",
                                      icon: "el-icon-caret-bottom",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.btnClick(row)
                                      },
                                      mouseover: function ($event) {
                                        return _vm.getFnsList(row)
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-caret-bottom",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row[column.field]) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2623696565
                  ),
                }),
                _vm._v(" "),
                _c("VxeTable", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeName == "3" && _vm.showCustoomerUseList,
                      expression: "activeName == '3' && showCustoomerUseList",
                    },
                  ],
                  ref: "table3",
                  attrs: {
                    temp: _vm.customer,
                    list: _vm.list3,
                    height: _vm.height - 170,
                    "list-loading": _vm.listLoading,
                    "list-query": _vm.listQuery,
                    scroll: { x: 8, y: 15 },
                    columns: _vm.columns3,
                    scrollTopLastLocation: false,
                  },
                  on: { click: _vm.handleClick },
                }),
                _vm._v(" "),
                _c("VxeTable", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeName == "4",
                      expression: "activeName == '4'",
                    },
                  ],
                  ref: "table4",
                  attrs: {
                    temp: _vm.customer,
                    list: _vm.list4,
                    height: _vm.height - 170,
                    "list-loading": _vm.listLoading,
                    "list-query": _vm.listQuery,
                    scroll: { x: 8, y: 15 },
                    columns: _vm.columns4,
                    scrollTopLastLocation: false,
                  },
                  on: { click: _vm.handleClick },
                }),
              ]
            : [
                _c("VxeTable", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeName == "4",
                      expression: "activeName == '4'",
                    },
                  ],
                  ref: "table4",
                  attrs: {
                    temp: _vm.customer,
                    list: _vm.list4,
                    scroll: { x: 8, y: 15 },
                    height: _vm.height - 170,
                    "list-loading": _vm.listLoading,
                    "list-query": _vm.listQuery,
                    columns: _vm.columns4,
                  },
                  on: { click: _vm.handleClick },
                }),
              ],
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName != "4",
                  expression: "activeName != '4'",
                },
              ],
              staticClass: "total2",
            },
            [
              _c("span", [
                _vm._v("应付金额: "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.totalMes2.cpyAccount ? _vm.totalMes2.cpyAccount : 0
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("欠款金额: "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.totalMes2.cpyExrate ? _vm.totalMes2.cpyExrate : 0
                    )
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName != "4",
                  expression: "activeName != '4'",
                },
              ],
              staticClass: "total",
            },
            [
              _c("span", [
                _vm._v("已付金额: "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.totalMes2.cpyPayamt ? _vm.totalMes2.cpyPayamt : 0
                    )
                  ),
                ]),
                _vm._v(" (现金/卡:\n        "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.totalMes2.cpyCardRmAmt
                        ? _vm.totalMes2.cpyCardRmAmt
                        : 0
                    )
                  ),
                ]),
                _vm._v(" 存款:\n        "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.totalMes2.cpyStoamt ? _vm.totalMes2.cpyStoamt : 0
                    )
                  ),
                ]),
                _vm._v(" 券:\n        "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.totalMes2.cpyOthamt ? _vm.totalMes2.cpyOthamt : 0
                    )
                  ),
                ]),
                _vm._v(" )"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName != "4",
                  expression: "activeName != '4'",
                },
              ],
              staticClass: "total",
            },
            [
              _c("span", [
                _vm._v("消耗金额: "),
                _c("strong", [_vm._v(_vm._s(_vm.totalMes2.cpyCuramt))]),
                _vm._v("(现金/卡/存款: "),
                _c("strong", [_vm._v(_vm._s(_vm.totalMes2.cpyUseMonAmt))]),
                _vm._v(" 券:\n        "),
                _c("strong", [_vm._v(_vm._s(_vm.totalMes2.cpyUseOthamt))]),
                _vm._v(" )"),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("项目余额: "),
                _c("strong", [_vm._v(_vm._s(_vm.totalMes2.cpyBalance))]),
                _vm._v(" (现金/卡/存款:\n        "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.totalMes2.cpyCardRmStoBalance)),
                ]),
                _vm._v(" 券:\n        "),
                _c("strong", [_vm._v(_vm._s(_vm.totalMes2.cpyOthBalance))]),
                _vm._v(" )"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName == "4",
                  expression: "activeName == '4'",
                },
              ],
              staticClass: "total",
            },
            [
              _c("span", [
                _vm._v("现金/卡: "),
                _c("strong", [_vm._v(_vm._s(_vm.sumList.cpyCardRmAmt))]),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("券支付: "),
                _c("strong", [_vm._v(_vm._s(_vm.sumList.ctpCoupamt))]),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("欠款: "),
                _c("strong", [_vm._v(_vm._s(_vm.sumList.ctpExrate))]),
              ]),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "el-drag-dialog", rawName: "v-el-drag-dialog" }],
          attrs: {
            visible: _vm.consumeDialogVisble,
            title: "业绩重算",
            width: "30%",
            top: "200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.consumeDialogVisble = $event
            },
            close: function ($event) {
              _vm.yjDisabled = false
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "margin-left": "10px" },
              attrs: {
                inline: true,
                model: _vm.csForm,
                size: "mini",
                "label-position": "right",
              },
            },
            [
              _vm.activeName == "4"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "推荐顾问:" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.advisers,
                            callback: function ($$v) {
                              _vm.advisers = $$v
                            },
                            expression: "advisers",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "助推/二级咨询:" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.diagnosis,
                            callback: function ($$v) {
                              _vm.diagnosis = $$v
                            },
                            expression: "diagnosis",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _c("HkForm", { ref: "KkForm", attrs: { temp: _vm.csForm } }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center", padding: "10px" } },
            [
              _vm.activeName == "4"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisibleYj = true
                        },
                      },
                    },
                    [_vm._v("业绩分配")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    disabled: _vm.yjDisabled,
                    size: "mini",
                  },
                  on: { click: _vm.sureAgainSum },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-dialog", {
        directives: [{ name: "el-drag-dialog", rawName: "v-el-drag-dialog" }],
        attrs: {
          visible: _vm.dialogVisibleFuc,
          title: "修改截止时间",
          width: "30%",
          top: "200px",
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisibleFuc = $event
          },
        },
      }),
      _vm._v(" "),
      _vm.dialogVisibleYj
        ? [
            _c("yj-dialog", {
              ref: "parfomanceSet",
              attrs: {
                operate: "add",
                advisersT: _vm.advisersT,
                diagnosisT: _vm.diagnosisT,
                customer: _vm.customer1,
                ProjectItem: _vm.projectItem,
              },
              on: {
                close: function ($event) {
                  _vm.dialogVisibleYj = false
                },
                setPay: _vm.setItemProject,
                empChangeSuccess: function ($event) {
                  _vm.dialogVisibleYj = false
                },
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }