import request from '@/utils/request'; // 现场咨询-咨询列表

export function findCallInfoPage(data, stillStates) {
  return request({
    url: '/manager/reception/callInfo/page',
    method: 'post',
    data: data,
    isTrim: true,
    stillStates: stillStates
  });
} // 现场设计列表

export function listCurentTabl(data) {
  return request({
    url: '/manager/curentMemo/listCurentTabl',
    method: 'post',
    params: data
  });
} // 电网保单记录列表

export function listCurentMemo(data) {
  return request({
    url: '/manager/curentMemo/listCurentMemo',
    method: 'post',
    params: data
  });
} // 当前咨询项目

export function pduList(data) {
  return request({
    url: '/manager/ctmCallPdu/listCurrentPdu',
    method: 'post',
    data: data
  });
} // 当前咨询项目

export function listCurrentPdu(data) {
  return request({
    url: '/manager/ctmCallPdu/listCurrentPdu',
    method: 'post',
    data: data
  });
} // 计算美容师默认比例接口

export function comBeaRatio(data) {
  return request({
    url: '/manager/ctmCallPdu/comBeaRatio',
    method: 'get',
    params: data
  });
} // 当前咨询情况-拼音查询

export function findByPy(data) {
  return request({
    url: '/manager/product/findByPy',
    method: 'get',
    params: data
  });
} //所有项目

export function getAllProductSelectVo(data) {
  return request({
    url: '/manager/project/listSelectVo',
    method: 'post',
    data: data
  });
} //所有套餐

export function getAllPthSelectVo(data) {
  return request({
    url: '/manager/producttool/getSelectList',
    method: 'post',
    data: data
  });
} // 当前咨询情况-保存

export function ctmCallPduAdds(data) {
  return request({
    url: '/manager/ctmCallPdu/adds',
    method: 'post',
    data: data
  });
} // 咨询项目类型列表

export function calpdutypePage(data) {
  return request({
    url: '/manager/calpdutype/page',
    method: 'post',
    data: data
  });
} // 现场咨询-过往咨询-根据现场客户咨询ID

export function listHistoryByCtmId(data) {
  return request({
    url: '/manager/reception/callInfo/listHistoryByCtmId',
    method: 'get',
    params: data
  });
} // 预约登记列表

export function listForConsult(data) {
  return request({
    url: '/manager/fuc/listForConsult',
    method: 'post',
    params: data
  });
} // 获取预约类型列表

export function fucType(data) {
  return request({
    url: '/manager/fuc/type',
    method: 'post',
    data: data
  });
} // 当前咨询情况-单次产品

export function findSingleList(data) {
  return request({
    url: '/manager/product/findSingleList',
    method: 'post',
    data: data
  });
} // 当前咨询情况-零购产品

export function findZeroPurchaseList(data) {
  return request({
    url: '/manager/product/findZeroPurchaseList',
    method: 'post',
    data: data
  });
} // 现场咨询-过往咨询-根据现场客户咨询ID

export function pageCallInfoPduList(data) {
  return request({
    url: '/manager/reception/callInfo/pageCallInfoPduList',
    method: 'post',
    params: data
  });
} // 确定接诊

export function callInfoJd(data) {
  return request({
    url: '/manager/reception/callInfo/jd',
    method: 'post',
    params: data
  });
} // 当前咨询项目-删除

export function deleteCurrentPdu(data) {
  return request({
    url: '/manager/ctmCallPdu/deleteCurrentPdu',
    method: 'get',
    params: data
  });
} // 当前咨询项目-客户人数明细

export function callInfoStat(data) {
  return request({
    url: '/manager/reception/callInfo/stat',
    method: 'post',
    data: data
  });
} // 当前咨询项目-客户人数明细

export function listCurrentPduT(data) {
  return request({
    url: '/manager/ctmCallPdu/hislistCurrentPdu',
    method: 'post',
    data: data
  });
} // 当前咨询项目-客户人数明细

export function getSymptonmList(data) {
  return request({
    url: '/manager/ctmCallPdu/getSymptonmList',
    method: 'get',
    params: data
  });
} //删除接诊

export function deleteJd(data) {
  return request({
    url: '/manager/reception/callInfo/deleteJd',
    method: 'get',
    params: data
  });
} //取消接诊

export function canJd(data) {
  return request({
    url: '/manager/reception/callInfo/canJd',
    method: 'get',
    params: data
  });
} //修改体验

export function updateTyStatus(data) {
  return request({
    url: '/manager/reception/callInfo/updateTyStatus',
    method: 'post',
    params: data
  });
} //修改体验-过往

export function hislistCurrentPduUpdateZhLabel(data) {
  return request({
    url: '/manager/ctmCallPdu/hislistCurrentPduUpdateZhLabel',
    method: 'post',
    params: data
  });
} //修改体验-过往人数

export function getCallInfoPduCusomerCnt(data) {
  return request({
    url: '/manager/ctmCallPdu/getCallInfoPduCusomerCnt',
    method: 'post',
    data: data
  });
} //修改体验-过往人数

export function getCtpFnsdateListByZptid(data) {
  return request({
    url: '/manager/op/getCtpFnsdateListByZptid',
    method: 'get',
    params: data
  });
} //欠款咨询

export function getCustomerSumDebt(data) {
  return request({
    url: '/manager/cashmanage/getCustomerSumDebt',
    method: 'get',
    params: data
  });
} //过往咨询列表导出

export function exportHislistCurrentPdu(data) {
  return request({
    url: '/manager/ctmCallPdu/export/hislistCurrentPdu',
    method: 'post',
    data: data,
    responseType: 'blob'
  });
} //过往咨询备注保存

export function ctmCallPduUpdateHisPdu(data) {
  return request({
    url: '/manager/ctmCallPdu/updateHisPdu',
    method: 'post',
    data: data
  });
} // 面部评估

export function ctmFacePage(data) {
  return request({
    url: '/manager/ctmFace/queryPage',
    method: 'post',
    data: data
  });
}
export function ctmFaceExport(data) {
  return request({
    url: '/manager/ctmFace/export',
    responseType: 'blob',
    method: 'post',
    data: data
  });
}
export function getByCtmcode(data) {
  return request({
    url: '/manager/ctmFace/getByCtmcode',
    method: 'get',
    params: data
  });
}
export function ctmFaceAddOrUpdate(data) {
  return request({
    url: '/manager/ctmFace/addOrUpdate',
    method: 'post',
    data: data
  });
}
export function ctmFaceDel(data) {
  return request({
    url: '/manager/ctmFace/delete',
    method: 'get',
    params: data
  });
}
export function getTreeList(data) {
  return request({
    url: '/manager/preProject/getTreeList',
    method: 'get',
    params: data
  });
}
export function getSkinReport(data) {
  return request({
    url: '/manger/yanjia/getSkipReport',
    method: 'post',
    data: data
  });
}