var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.uploadTitle
        ? _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.uploadTitle)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.btnUpload
            ? "miniapp-img-upload-btn"
            : "miniapp-img-upload",
        },
        [
          _c(
            "el-upload",
            {
              ref: "elUpload",
              class: _vm.btnUpload ? "" : "upload-demo",
              attrs: {
                drag: _vm.drag,
                action: _vm.uploadURL,
                "before-upload": _vm.onBeforeUpload,
                "list-type": _vm.listType,
                name: "file",
                "on-preview": _vm.handlePictureCardPreview,
                "on-remove": _vm.handleRemove,
                "before-remove": _vm.beforeRemove,
                "on-success": _vm.handleSuccess,
                "on-error": _vm.handleError,
                multiple: _vm.multiple,
                limit: _vm.limitNum,
                "on-exceed": _vm.handleExceed,
                "file-list": _vm.picWebUrlList,
                "show-file-list": _vm.showFileList,
                headers: _vm.headersToken,
                "on-change": _vm.picChange,
                "on-progress": _vm.picUpIng,
              },
              on: {
                "update:fileList": function ($event) {
                  _vm.picWebUrlList = $event
                },
                "update:file-list": function ($event) {
                  _vm.picWebUrlList = $event
                },
              },
            },
            [
              _vm.btnUpload
                ? [_vm._t("btnText")]
                : [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _vm._v("将图片拖到此处，或"),
                      _c("em", [_vm._v("点击上传")]),
                    ]),
                    _vm._v(" "),
                    _vm.imgSize
                      ? _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            staticStyle: { margin: "0 20px" },
                          },
                          [
                            _vm._v(
                              "只能上传jpg/png文件,且请上传像素为 " +
                                _vm._s(_vm.imgSize.width) +
                                "*" +
                                _vm._s(_vm.imgSize.height) +
                                " 像素"
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.imgContorl
                      ? _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            staticStyle: { margin: "0 20px" },
                          },
                          [
                            _vm._v(
                              "只能上传jpg/png文件,且请上传像素不超过" +
                                _vm._s(_vm.imgContorl) +
                                "M的图片"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }