/*
 * @Author: yangchao
 * @Date: 2020-08-17 15:42:22
 * @LastEditors: yangchao
 * @LastEditTime: 2021-12-17 09:13:10
 * @Description:
 */

/**
 * 动态加载百度地图api函数
 * @param {String} ak  百度地图AK，必传
 */
export default function loadBMap(ak) {
  return new Promise(function (resolve, reject) {
    if (typeof window.BMap !== 'undefined') {
      resolve(window.BMap);
      return true;
    }

    window.onBMapCallback = function () {
      resolve(window.BMap);
    };

    var httpOrHttps = location.protocol;
    console.log('local', location);
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = httpOrHttps + '//api.map.baidu.com/api?v=3.0&ak=' + ak + '&callback=onBMapCallback&s=1';
    script.onerror = reject;
    document.head.appendChild(script);
  });
}