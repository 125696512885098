//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { ctmrvname, ctmrvdetail } from '@/api/returnVisit';
import BaseTable from "../components/BaseTable.vue";
export default {
  name: 'GwTable',
  components: {
    BaseTable: BaseTable
  },
  props: {
    customer: {
      required: true,
      type: Object
    },
    ctfZdDocList: {
      required: true,
      type: Array
    },
    height: {
      required: true
    }
  },
  filters: {
    statusFilter: function statusFilter(status, type) {
      var map = {
        USE: {
          type: 'success',
          title: '正常'
        },
        STO: {
          type: 'primary',
          title: '删除'
        }
      };
      return map[status] ? map[status][type] : status;
    }
  },
  watch: {
    temp: function temp(val) {
      if (val.ctnCode) {
        this.getList2();
      }
    }
  },
  data: function data() {
    return {
      curCell: {},
      rvPos: '',
      empList: [{
        title: '咨询',
        field: 1
      }, {
        title: '护理师/脱毛师',
        field: 2
      }],
      radio: null,
      list1: [],
      list2: [],
      listLoading2: false,
      dialogVisible: false,
      total1: 0,
      total2: 0,
      temp: {},
      columns1: [],
      columns2: [],
      stateList: [{
        title: '接通',
        field: 0
      }, {
        title: '关机',
        field: 0
      }, {
        title: '无人接听',
        field: 0
      }, {
        title: '停机',
        field: 0
      }, {
        title: '重打',
        field: 0
      }, {
        title: '拒接',
        field: 0
      }, {
        title: '空号',
        field: 0
      }, {
        title: '有效',
        field: 0
      }],
      lostReasonList: [{
        title: '考虑',
        field: 0
      }, {
        title: '时间',
        field: 0
      }, {
        title: '转医院',
        field: 0
      }, {
        title: '价格',
        field: 0
      }, {
        title: '安全',
        field: 0
      }, {
        title: '不考虑',
        field: 0
      }, {
        title: '对比',
        field: 0
      }, {
        title: '预约',
        field: 0
      }],
      listQuery1: {
        pageNum: 1,
        pageSize: 1000
      },
      listQuery2: {
        pageNum: 1,
        pageSize: 1000
      },
      listCopy: []
    };
  },
  created: function created() {
    this.columns1 = [{
      title: '编号',
      field: 'ctnCode',
      width: '120px'
    }, {
      title: '回访计划名称',
      field: 'ctnName',
      width: '140px'
    }, {
      title: '说明',
      field: 'ctnRemark',
      width: '180px'
    }];
    this.columns2 = [{
      title: '跟进天数',
      field: 'ctnDays',
      width: '80px'
    }, {
      title: '回访类型',
      field: 'ctnRvtype',
      width: '80px'
    }, // TODOZOU
    {
      title: '是否设为操作员强制跟进',
      field: 'rviNext',
      width: '140px'
    }, {
      title: '回访人员',
      field: 'ctnEmp',
      notColumn: true,
      width: '140px'
    }, {
      title: '回访类型',
      field: 'ctnStatus',
      width: '140px',
      filter: this.$options.filters.statusFilter
    }, {
      title: '说明',
      field: 'ctnRemark',
      width: '140px'
    }];
    this.getList1();
  },
  methods: {
    getList1: function getList1() {
      var _this = this;

      this.$setTableLoading(true);
      this.listQuery1.ctnStatus = 'USE';
      ctmrvname(this.listQuery1).then(function (response) {
        if (response && response.data) {
          _this.list1 = response.data;
        }

        _this.total1 = _this.list1.length;

        if (_this.list1.length > 0) {
          _this.temp = JSON.parse(JSON.stringify(_this.list1[0]));
        }

        _this.$setTableLoading(false);
      });
    },
    getList2: function getList2() {
      var _this2 = this;

      this.$setTableLoading(true);
      this.listQuery2.ctnCode = this.temp.ctnCode;
      ctmrvdetail(this.listQuery2).then(function (response) {
        if (response && response.data) {
          _this2.list2 = _this2.rvPos ? response.data.filter(function (v) {
            return v.rvPos === _this2.rvPos;
          }) : response.data;
          _this2.listCopy = JSON.parse(JSON.stringify(response.data));
        }

        _this2.total2 = _this2.list2.length;

        _this2.$setTableLoading(false);
      });
    },
    handleClick1: function handleClick1(row) {
      this.temp = row;
    },
    handleClick2: function handleClick2(row) {
      this.temp = row;
    },
    radioChange: function radioChange(e) {
      var value = e.target.value;

      if (value === '1') {
        if (this.rvPos) {
          this.rvPos = null;
        }
      }

      if (value === '2') {
        if (this.rvPos) {
          this.rvPos = null;
        }
      }

      this.getList2(); // console.log('e', e);
    },
    handleDbClick1: function handleDbClick1() {// this.$emit('done',row);
    },
    handleCancel: function handleCancel() {
      this.$emit('cancel');
    },
    handleDone: function handleDone() {
      this.$emit('done', this.list2);
    },
    tableCellClassName: function tableCellClassName(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex;
      column.index = columnIndex - 1;
    }
  }
};