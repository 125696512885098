// 新客留存
export var newCtmRetentionCol = [{
  title: '',
  type: 'expand',
  width: '10px',
  fixed: 'left',
  expandData: 'customerList',
  expandCol: [{
    key: 'ctmCode',
    label: '客户卡号',
    width: 160
  }, {
    key: 'ctmName',
    label: '客户姓名',
    width: 120
  }, {
    key: 'pay3Month100Amt',
    label: '三个月付款总额',
    width: 160
  }, {
    key: 'ctmEmpname1',
    label: '所属顾问',
    width: 150
  }, {
    key: 'ctmSource3Name',
    label: '所属护理师',
    width: 150
  }, {
    key: 'ctmSource4Name',
    label: '所属美容师',
    width: 150
  }]
}, {
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, {
  title: '员工编号',
  field: 'empCode',
  width: '120px'
}, {
  title: '员工姓名',
  field: 'empName',
  width: '120px'
}, {
  title: '总人数',
  field: 'peopleNum',
  width: '80px'
}, {
  title: '三个月付款>=100',
  field: 'ctmCode',
  children: [{
    title: '人数',
    field: 'peopleNum3Month100Amt',
    width: '80px'
  }, {
    title: '留存率',
    field: 'rate3Month100Amt',
    width: '80px'
  }]
}, {
  title: '三个月付款>=500',
  field: 'ctmCode',
  children: [{
    title: '人数',
    field: 'peopleNum3Month500Amt',
    width: '80px'
  }, {
    title: '留存率',
    field: 'rate3Month500Amt',
    width: '80px'
  }]
}, {
  title: '三个月付款>=1000',
  field: 'ctmCode',
  children: [{
    title: '人数',
    field: 'peopleNum3Month1000Amt',
    width: '80px'
  }, {
    title: '留存率',
    field: 'rate3Month1000Amt',
    width: '80px'
  }]
}, {
  title: '三个月付款>=2000',
  field: 'ctmCode',
  children: [{
    title: '人数',
    field: 'peopleNum3Month2000Amt',
    width: '80px'
  }, {
    title: '留存率',
    field: 'rate3Month2000Amt',
    width: '80px'
  }]
}];
export var newCtmRetentionCol1 = [{
  title: '',
  type: 'expand',
  width: '10px',
  fixed: 'left',
  expandData: 'customerList',
  expandCol: [{
    key: 'ctmCode',
    label: '客户卡号',
    width: 160
  }, {
    key: 'ctmName',
    label: '客户姓名',
    width: 120
  }, {
    key: 'ctmDate',
    label: '初诊日期',
    width: 120
  }, {
    key: 'pay3Month100Amt',
    label: '三个月付款总额',
    width: 160
  }, {
    key: 'ctmEmpname1',
    label: '所属顾问',
    width: 150
  }, {
    key: 'ctmSource3Name',
    label: '所属护理师',
    width: 150
  }, {
    key: 'ctmSource4Name',
    label: '所属美容师',
    width: 150
  }]
}, {
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, {
  title: '总人数',
  field: 'peopleNum',
  width: '80px'
}, {
  title: '三个月付款>=100',
  field: 'ctmCode',
  width: '80px',
  children: [{
    title: '人数',
    field: 'peopleNum3Month100Amt',
    width: '80px'
  }, {
    title: '留存率',
    field: 'rate3Month100Amt',
    width: '80px'
  }]
}, {
  title: '三个月付款>=500',
  field: 'ctmCode',
  width: '80px',
  children: [{
    title: '人数',
    field: 'peopleNum3Month500Amt',
    width: '80px'
  }, {
    title: '留存率',
    field: 'rate3Month500Amt',
    width: '80px'
  }]
}, {
  title: '三个月付款>=1000',
  field: 'ctmCode',
  width: '80px',
  children: [{
    title: '人数',
    field: 'peopleNum3Month1000Amt',
    width: '80px'
  }, {
    title: '留存率',
    field: 'rate3Month1000Amt',
    width: '80px'
  }]
}, {
  title: '三个月付款>=2000',
  field: 'ctmCode',
  width: '80px',
  children: [{
    title: '人数',
    field: 'peopleNum3Month2000Amt',
    width: '80px'
  }, {
    title: '留存率',
    field: 'rate3Month2000Amt',
    width: '80px'
  }]
}]; // 老客保有

export var oldCtmTenureCol = [{
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, {
  title: '员工编号',
  field: 'empCode',
  width: '120px'
}, {
  title: '员工姓名',
  field: 'empName',
  width: '120px'
}, {
  title: '总人数',
  field: 'peopleNum',
  width: '80px'
}, {
  title: '3个月上门',
  field: 'intId',
  children: [{
    title: '保有人数',
    field: 'peopleNum3Month',
    width: '80px'
  }, {
    title: '保有率',
    field: 'rate3Month',
    width: '80px'
  }]
}, {
  title: '6个月上门',
  field: 'intId',
  children: [{
    title: '保有人数',
    field: 'peopleNum6Month',
    width: '80px'
  }, {
    title: '保有率',
    field: 'rate6Month',
    width: '80px'
  }]
}, {
  title: '9个月上门',
  field: 'intId',
  children: [{
    title: '保有人数',
    field: 'peopleNum9Month',
    width: '80px'
  }, {
    title: '保有率',
    field: 'rate9Month',
    width: '80px'
  }]
}, {
  title: '12个月上门',
  field: 'intId',
  children: [{
    title: '保有人数',
    field: 'peopleNum12Month',
    width: '80px'
  }, {
    title: '保有率',
    field: 'rate12Month',
    width: '80px'
  }]
}];
export var oldCtmTenureCol1 = [{
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, {
  title: '总人数',
  field: 'peopleNum',
  width: '80px'
}, {
  title: '12个月上门',
  field: 'intId',
  width: '80px',
  children: [{
    title: '保有人数',
    field: 'peopleNum12Month',
    width: '80px'
  }, {
    title: '保有率',
    field: 'rate12Month',
    width: '80px'
  }]
}, {
  title: '9个月上门',
  field: 'intId',
  children: [{
    title: '保有人数',
    field: 'peopleNum9Month',
    width: '80px'
  }, {
    title: '保有率',
    field: 'rate9Month',
    width: '80px'
  }]
}, {
  title: '6个月上门',
  field: 'intId',
  children: [{
    title: '保有人数',
    field: 'peopleNum6Month',
    width: '80px'
  }, {
    title: '保有率',
    field: 'rate6Month',
    width: '80px'
  }]
}, {
  title: '3个月上门',
  field: 'intId',
  children: [{
    title: '保有人数',
    field: 'peopleNum3Month',
    width: '80px'
  }, {
    title: '保有率',
    field: 'rate3Month',
    width: '80px'
  }]
}]; // 黄金顾客保有

export var goldCtmTenureCol = [{
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, {
  title: '员工编号',
  field: 'empCode',
  width: '120px'
}, {
  title: '员工姓名',
  field: 'empName',
  width: '120px'
}, {
  title: '总人数',
  field: 'peopleNum',
  width: '100px'
}, {
  title: '收款总额',
  field: 'totalAmt',
  width: '100px'
}, {
  title: '人均贡献',
  field: 'avgAmt',
  width: '100px'
}, {
  title: '1年上门',
  field: 'intCurdate',
  width: '100px',
  children: [{
    title: '保有人数',
    field: 'peopleNum12Month',
    width: '100px'
  }, {
    title: '保有率',
    field: 'rate12Month',
    width: '100px'
  }, {
    title: '收款金额',
    field: 'amt12Month',
    width: '100px'
  }]
}, {
  title: '9个月上门',
  field: 'intCurdate',
  width: '100px',
  children: [{
    title: '保有人数',
    field: 'peopleNum9Month',
    width: '100px'
  }, {
    title: '保有率',
    field: 'rate9Month',
    width: '100px'
  }, {
    title: '收款金额',
    field: 'amt9Month',
    width: '100px'
  }]
}, {
  title: '6个月上门',
  field: 'intCurdate',
  width: '100px',
  children: [{
    title: '保有人数',
    field: 'peopleNum6Month',
    width: '100px'
  }, {
    title: '保有率',
    field: 'rate6Month',
    width: '100px'
  }, {
    title: '收款金额',
    field: 'amt6Month',
    width: '100px'
  }]
}, {
  title: '3个月上门',
  field: 'intCurdate',
  width: '100px',
  children: [{
    title: '保有人数',
    field: 'peopleNum3Month',
    width: '100px'
  }, {
    title: '保有率',
    field: 'rate3Month',
    width: '100px'
  }, {
    title: '收款金额',
    field: 'amt3Month',
    width: '100px'
  }]
}];
export var goldCtmTenureCol1 = [{
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, // { title: "员工编号", field: "empCode",  width: "120px" },
// { title: "员工姓名", field: "empName",  width: "120px" },
{
  title: '总人数',
  field: 'peopleNum',
  width: '120px'
}, {
  title: '收款总额',
  field: 'totalAmt',
  width: '100px'
}, {
  title: '人均贡献',
  field: 'avgAmt',
  width: '100px'
}, {
  title: '1年上门',
  field: 'intCurdate',
  width: '100px',
  children: [{
    title: '保有人数',
    field: 'peopleNum12Month',
    width: '100px'
  }, {
    title: '保有率',
    field: 'rate12Month',
    width: '100px'
  }, {
    title: '收款金额',
    field: 'amt12Month',
    width: '100px'
  }]
}, {
  title: '9个月上门',
  field: 'intCurdate',
  width: '100px',
  children: [{
    title: '保有人数',
    field: 'peopleNum9Month',
    width: '100px'
  }, {
    title: '保有率',
    field: 'rate9Month',
    width: '100px'
  }, {
    title: '收款金额',
    field: 'amt9Month',
    width: '100px'
  }]
}, {
  title: '6个月上门',
  field: 'intCurdate',
  width: '100px',
  children: [{
    title: '保有人数',
    field: 'peopleNum6Month',
    width: '100px'
  }, {
    title: '保有率',
    field: 'rate6Month',
    width: '100px'
  }, {
    title: '收款金额',
    field: 'amt6Month',
    width: '100px'
  }]
}, {
  title: '3个月上门',
  field: 'intCurdate',
  width: '100px',
  children: [{
    title: '保有人数',
    field: 'peopleNum3Month',
    width: '100px'
  }, {
    title: '保有率',
    field: 'rate3Month',
    width: '100px'
  }, {
    title: '收款金额',
    field: 'amt3Month',
    width: '100px'
  }]
}]; // 朋友介绍

export var friendIntroductionCol = [{
  title: '',
  type: 'expand',
  width: '10px',
  searchable: false,
  expandData: 'ctmLdxVos',
  expandCol: [{
    key: 'ctmCode',
    label: '客户卡号',
    width: '120px'
  }, {
    key: 'ctmName',
    label: '客户姓名',
    width: '120px'
  }, {
    key: 'ctmDate',
    label: '初诊日期',
    width: '160px'
  }, {
    key: 'ctmPsumamt',
    label: '付款总额',
    width: '100px'
  }, {
    key: 'ctmEmpname1',
    label: '所属顾问',
    width: '100px'
  }, {
    key: 'ctmSource3Name',
    label: '所属护理师',
    width: '100px'
  }, {
    key: 'ctmSource4Name',
    label: '所属美容师',
    width: '100px'
  }, {
    key: 'ctmDept',
    label: '所属部门',
    width: '120px'
  }, {
    key: 'kfPeopleName',
    label: '开发人员',
    width: '120px'
  }, {
    key: 'ctfJsPeople',
    label: '介绍人',
    width: '120px'
  }, {
    key: 'ctfJsCtmcode',
    label: '介绍人卡号',
    width: '120px'
  }, {
    key: 'ctfJsMobile',
    label: '介绍人联系电话',
    width: '140px'
  }]
}, {
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, {
  title: '员工编号',
  field: 'empCode',
  width: '120px'
}, {
  title: '员工姓名',
  field: 'empName',
  width: '120px'
}, {
  title: '朋友介绍',
  field: 'intCurdate',
  width: '120px',
  children: [{
    title: '人数',
    field: 'peopleNum',
    width: '120px'
  }, {
    title: '收款总额',
    field: 'ctmPayamt',
    width: '120px'
  }, {
    title: '客单价',
    field: 'oneCtmPayamt',
    width: '120px'
  }, {
    title: '月均人数',
    field: 'avvMonthPeople',
    width: '120px'
  }]
}];
export var friendIntroductionCol1 = [{
  title: '',
  type: 'expand',
  width: '10px',
  searchable: false,
  expandData: 'ctmLdxVos',
  expandCol: [{
    key: 'ctmCode',
    label: '客户卡号',
    width: '120px'
  }, {
    key: 'ctmName',
    label: '客户姓名',
    width: '120px'
  }, {
    key: 'ctmDate',
    label: '初诊日期',
    width: '140px'
  }, {
    key: 'ctmPsumamt',
    label: '付款总额',
    width: '100px'
  }, {
    key: 'ctmEmpname1',
    label: '所属顾问',
    width: '100px'
  }, {
    key: 'ctmSource3Name',
    label: '所属护理师',
    width: '100px'
  }, {
    key: 'ctmSource4Name',
    label: '所属美容师',
    width: '100px'
  }, {
    key: 'ctmDept',
    label: '所属部门',
    width: '120px'
  }, {
    key: 'kfPeopleName',
    label: '开发人员',
    width: '120px'
  }, {
    key: 'ctfJsPeople',
    label: '介绍人',
    width: '100px'
  }, {
    key: 'ctfJsCtmcode',
    label: '介绍人卡号',
    width: '120px'
  }, {
    key: 'ctfJsMobile',
    label: '介绍人联系电话',
    width: '140px'
  }]
}, {
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, {
  title: '朋友介绍',
  field: 'intCurdate',
  width: '120px',
  children: [{
    title: '人数',
    field: 'peopleNum',
    width: '120px'
  }, {
    title: '收款总额',
    field: 'ctmPayamt',
    width: '120px'
  }, {
    title: '客单价',
    field: 'oneCtmPayamt',
    width: '120px'
  }, {
    title: '月均人数',
    field: 'avvMonthPeople',
    width: '120px'
  }]
}]; // 老带新

export var oldBringsNewCol = [{
  title: '',
  type: 'expand',
  width: '10px',
  searchable: false,
  expandData: 'ctmLdxVos',
  expandCol: [{
    key: 'ctmCode',
    label: '客户卡号',
    width: '130px'
  }, {
    key: 'ctmName',
    label: '客户姓名',
    width: '100px'
  }, {
    key: 'ctmDate',
    label: '初诊日期',
    width: '140px'
  }, {
    key: 'ctmPsumamt',
    label: '付款总额',
    width: '100px'
  }, {
    key: 'ctmEmpname1',
    label: '所属顾问',
    width: '100px'
  }, {
    key: 'ctmSource3Name',
    label: '所属护理师',
    width: '100px'
  }, {
    key: 'ctmSource4Name',
    label: '所属美容师',
    width: '100px'
  }, {
    key: 'ctmDept',
    label: '所属部门',
    width: '100px'
  }, {
    key: 'kfPeopleName',
    label: '开发人员',
    width: '100px'
  }, {
    key: 'ctfJsPeople',
    label: '介绍人',
    width: '100px'
  }, {
    key: 'ctfJsCtmcode',
    label: '介绍人卡号',
    width: '120px'
  }, {
    key: 'ctfJsMobile',
    label: '介绍人联系电话',
    width: '120px'
  }]
}, {
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, {
  title: '员工编号',
  field: 'empCode',
  width: '120px'
}, {
  title: '员工姓名',
  field: 'empName',
  width: '120px'
}, {
  title: '老带新',
  field: 'intCurdate',
  width: '120px',
  children: [{
    title: '人数',
    field: 'peopleNum',
    width: '120px'
  }, {
    title: '收款总额',
    field: 'ctmPayamt',
    width: '120px'
  }, {
    title: '客单价',
    field: 'oneCtmPayamt',
    width: '120px'
  }, {
    title: '月均人数',
    field: 'avvMonthPeople',
    width: '120px'
  }]
}];
export var oldBringsNewCol1 = [{
  title: '',
  type: 'expand',
  width: '10px',
  searchable: false,
  expandData: 'ctmLdxVos',
  expandCol: [{
    key: 'ctmCode',
    label: '客户卡号',
    width: '130px'
  }, {
    key: 'ctmName',
    label: '客户姓名',
    width: '100px'
  }, {
    key: 'ctmDate',
    label: '初诊日期',
    width: '140px'
  }, {
    key: 'ctmPsumamt',
    label: '付款总额',
    width: '100px'
  }, {
    key: 'ctmEmpname1',
    label: '所属顾问',
    width: '100px'
  }, {
    key: 'ctmSource3Name',
    label: '所属护理师',
    width: '100px'
  }, {
    key: 'ctmSource4Name',
    label: '所属美容师',
    width: '100px'
  }, {
    key: 'ctmDept',
    label: '所属部门',
    width: '100px'
  }, {
    key: 'kfPeopleName',
    label: '开发人员',
    width: '100px'
  }, {
    key: 'ctfJsPeople',
    label: '介绍人',
    width: '100px'
  }, {
    key: 'ctfJsCtmcode',
    label: '介绍人卡号',
    width: '120px'
  }, {
    key: 'ctfJsMobile',
    label: '介绍人联系电话',
    width: '120px'
  }]
}, {
  title: '所属机构',
  field: 'earName',
  width: '120px'
}, {
  title: '老带新',
  field: 'intCurdate',
  width: '120px',
  children: [{
    title: '人数',
    field: 'peopleNum',
    width: '120px'
  }, {
    title: '收款总额',
    field: 'ctmPayamt',
    width: '120px'
  }, {
    title: '客单价',
    field: 'oneCtmPayamt',
    width: '120px'
  }, {
    title: '月均人数',
    field: 'avvMonthPeople',
    width: '120px'
  }]
}];
export var columns6 = [{
  title: '开始时间',
  field: 'intCurdate',
  width: '120px'
}, {
  title: '结束时间',
  field: 'intCurdate',
  width: '120px'
}, {
  title: '所属分院',
  field: 'intCurdate',
  width: '120px'
}, {
  title: '引流类型',
  field: 'intCurdate',
  width: '120px'
}, {
  title: '营业日期',
  field: 'intCurdate',
  notColumn: true,
  width: '120px'
}, {
  title: '形象顾问',
  field: 'intCurdate',
  width: '120px'
}, {
  title: '护理师',
  field: 'intCurdate',
  width: '120px'
}, {
  title: '脱毛师',
  field: 'intCurdate',
  width: '120px'
}, {
  title: '总人数',
  field: 'ctmCode',
  width: '80px'
}, {
  title: '消费>=100',
  field: 'ctmCode',
  width: '80px',
  children: [{
    title: '当前人数',
    field: 'ctmName',
    width: '120px'
  }, {
    title: '三个月内人数',
    field: 'intId',
    width: '120px'
  }, {
    title: '三个月内留存率',
    field: 'intPageid',
    width: '120px'
  }]
}, {
  title: '消费>=500',
  field: 'ctmCode',
  width: '80px',
  children: [{
    title: '当前人数',
    field: 'ctmName',
    width: '120px'
  }, {
    title: '三个月内人数',
    field: 'intId',
    width: '120px'
  }, {
    title: '三个月内留存率',
    field: 'intPageid',
    width: '120px'
  }]
}, {
  title: '消费>=1000',
  field: 'ctmCode',
  width: '80px',
  children: [{
    title: '当前人数',
    field: 'ctmName',
    width: '120px'
  }, {
    title: '三个月内人数',
    field: 'intId',
    width: '120px'
  }, {
    title: '三个月内留存率',
    field: 'intPageid',
    width: '120px'
  }]
}, {
  title: '消费>=2000',
  field: 'ctmCode',
  width: '80px',
  children: [{
    title: '当前人数',
    field: 'ctmName',
    width: '120px'
  }, {
    title: '三个月内人数',
    field: 'intId',
    width: '120px'
  }, {
    title: '三个月内留存率',
    field: 'intPageid',
    width: '120px'
  }]
}];
export var timeStepList = [{
  title: '一个月',
  field: '1'
}, {
  title: '二个月',
  field: '2'
}, {
  title: '三个月',
  field: '3'
}, {
  title: '四个月',
  field: '4'
}, {
  title: '五个月',
  field: '5'
}, {
  title: '六个月',
  field: '6'
}, {
  title: '七个月',
  field: '7'
}, {
  title: '八个月',
  field: '8'
}, {
  title: '九个月',
  field: '9'
}, {
  title: '十个月',
  field: '10'
}, {
  title: '十一个月',
  field: '11'
}, {
  title: '十二个月',
  field: '12'
}, {
  title: '十三个月',
  field: '13'
}, {
  title: '十四个月',
  field: '14'
}, {
  title: '十五个月',
  field: '15'
}, {
  title: '十六个月',
  field: '16'
}, {
  title: '十七个月',
  field: '17'
}, {
  title: '十八个月',
  field: '18'
}, {
  title: '十九个月',
  field: '19'
}, {
  title: '二十个月',
  field: '20'
}, {
  title: '二十一个月',
  field: '21'
}, {
  title: '二十二个月',
  field: '22'
}, {
  title: '二十三个月',
  field: '23'
}, {
  title: '二十四个月',
  field: '24'
}]; // 改派条件

export var gpForm = [{
  itemProps: {
    label: '所属分院:'
  },
  type: 'select',
  column: {
    field: 'belongEarId',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '客户卡号:'
  },
  column: {
    field: 'ctmCode'
  },
  type: 'input',
  span: 4
}, {
  itemProps: {
    label: '姓名:'
  },
  column: {
    field: 'ctmName'
  },
  type: 'input',
  span: 4
}, {
  itemProps: {
    label: '联系电话:'
  },
  column: {
    field: 'ctmMobile'
  },
  type: 'input',
  span: 4
}, {
  itemProps: {
    label: '客户类别:'
  },
  type: 'select',
  column: {
    field: 'ctmLevel',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '客户状态:'
  },
  type: 'select',
  column: {
    field: 'ctmDealType',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '开发人员:'
  },
  type: 'select',
  column: {
    field: 'kfPeople',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '所属顾问:'
  },
  type: 'select',
  column: {
    field: 'ctfEmpCode',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '所属护理师:'
  },
  type: 'select',
  column: {
    field: 'ctfNurse',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '所属美容师:'
  },
  type: 'select',
  column: {
    field: 'ctfBeautician',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '所属医生:'
  },
  type: 'select',
  column: {
    field: 'ctmDoc',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '激活人:'
  },
  type: 'select',
  column: {
    field: 'activOpCode',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '激活管理:'
  },
  type: 'select',
  column: {
    field: 'activId',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '初诊日期:'
  },
  type: 'dateRange',
  column: {
    field: 'ctmDateStart',
    field1: 'ctmDateEnd'
  },
  span: 4
}, {
  itemProps: {
    label: '最近光临:'
  },
  type: 'dateRange',
  column: {
    field: 'ctmCrndateStart',
    field1: 'ctmCrndateEnd'
  },
  span: 4
}, {
  itemProps: {
    label: '最近光临:'
  },
  type: 'numRange',
  column: {
    field: 'ctmMinPsumamt',
    field1: 'ctmMaxPsumamt'
  },
  span: 4
}, {
  itemProps: {
    label: '回访:'
  },
  type: 'select',
  column: {
    field: 'rvStatus',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: '未回访天数:'
  },
  column: {
    field: 'noRvDay'
  },
  type: 'input',
  span: 4
}, {
  itemProps: {
    label: '原所属顾问:'
  },
  type: 'select',
  column: {
    field: 'ctfEmpCodeOld',
    searchList: []
  },
  span: 4
}, {
  itemProps: {
    label: 'RFM类别:'
  },
  type: 'SelectAddCar',
  column: {
    field: 'ctfEmpCodeOld'
  },
  span: 4
}];
export var tmLableList = [{
  title: '纯脱毛',
  field: 1
}, {
  title: '空',
  field: -1
}];
export var tmLableList2 = [{
  title: '转化',
  field: 1
}, {
  title: '空',
  field: -1
}];
export var tyTypeList = [{
  title: '脱毛',
  field: 1
}, {
  title: '护理',
  field: 2
}, {
  title: '超光子',
  field: 3
}, {
  title: '其他',
  field: 4
}];
export var szStatusList = [{
  title: '未转',
  field: 0
}, {
  title: '转化',
  field: 1
}];