var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "table-container",
          style: { height: _vm.height + "px" },
        },
        [
          _c("edit-table", {
            ref: "table",
            attrs: {
              temp: _vm.customer,
              list: _vm.list,
              height: _vm.height,
              "list-query": _vm.listQuery,
              columns: _vm.columns,
            },
            on: { click: _vm.handleClick },
          }),
          _vm._v(" "),
          !_vm.onlyRead
            ? _c(
                "div",
                { staticClass: "centerBtn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", plain: "" },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("增加")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        size: "mini",
                        plain: "",
                        type: "danger",
                        disabled: !this.temp.tempid,
                      },
                      on: { click: _vm.handleDel },
                    },
                    [_vm._v("\n        删除\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "面诊方案查询",
                visible: _vm.dialogVisible,
                top: "50px",
                "close-on-click-modal": false,
                center: "",
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("mb-table", {
                ref: "dialog4",
                attrs: {
                  customer: _vm.customer,
                  "ctf-zd-doc-list": _vm.employees,
                  height: _vm.innerHeight - 180,
                },
                on: { cancel: _vm.handleCancel, select: _vm.handleSelect },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }