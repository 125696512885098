//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getFaceLevel3List } from "@/api/consult";
import VxeTable from "../components/VxeTable";
export default {
  name: 'mbTable',
  components: {
    VxeTable: VxeTable
  },
  props: {
    customer: {
      required: true,
      type: Object
    },
    ctfZdDocList: {
      required: true,
      type: Array
    },
    height: {
      required: true
    }
  },
  data: function data() {
    return {
      curCell: {},
      list1: [],
      list2: [],
      dialogVisible: false,
      total: 0,
      temp: {},
      columns1: [],
      columns2: [],
      listQuery: {
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  watch: {
    customer: function customer(val) {
      if (val.ctmCode) {
        this.getList1();
        this.getList2();
      }
    }
  },
  created: function created() {
    this.columns2 = [{
      title: '一级',
      field: 'p1Name',
      width: '80px'
    }, {
      title: '二级',
      field: 'p2Name',
      width: '80px'
    }, {
      title: '三级',
      field: 'projectName',
      width: '80px'
    }];
    this.getList2();
  },
  methods: {
    selectItem: function selectItem(_ref) {
      var selection = _ref.selection,
          row = _ref.row;
    },
    allSelect: function allSelect(_allSelect) {},
    selectInit: function selectInit(_ref2) {
      var row = _ref2.row;
    },
    getColumnValue: function getColumnValue(value, list) {
      var doc = {
        title: ''
      };

      if (list) {
        list.map(function (item) {
          if (item.key == value) {
            doc = item;
          }
        });
      }

      return doc.title;
    },
    getList2: function getList2() {
      var _this = this;

      this.$setTableLoading(true);
      var data = {
        pageNum: 1,
        pageSize: 200
      };
      getFaceLevel3List(data).then(function (response) {
        if (response.data) {
          _this.list2 = response.data;

          _this.$setTableLoading(false);
        }
      });
    },
    handleClick: function handleClick(row, column) {
      this.customer = row;
      this.$emit('select', this.customer);
    }
  }
};