var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fixed_table",
      style: {
        height: _vm.height + "px",
        overflow: !_vm.rvGwType ? "scroll" : "auto",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "table-container",
          style: { height: _vm.height / 2 + 50 + _vm.autoHeight + "px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-button", { attrs: { type: "text" } }, [
                    _vm._v("现场设计情况(项目+产品+预收)："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown",
                    { on: { command: _vm.heightSelect } },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v("\n            高度控制"),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("el-dropdown-item", { attrs: { command: 0 } }, [
                            _vm._v("-100"),
                          ]),
                          _vm._v(" "),
                          _c("el-dropdown-item", { attrs: { command: 50 } }, [
                            _vm._v("-50"),
                          ]),
                          _vm._v(" "),
                          _c("el-dropdown-item", { attrs: { command: 100 } }, [
                            _vm._v("0"),
                          ]),
                          _vm._v(" "),
                          _c("el-dropdown-item", { attrs: { command: 150 } }, [
                            _vm._v("+50"),
                          ]),
                          _vm._v(" "),
                          _c("el-dropdown-item", { attrs: { command: 200 } }, [
                            _vm._v("+100"),
                          ]),
                          _vm._v(" "),
                          _c("el-dropdown-item", { attrs: { command: 250 } }, [
                            _vm._v("+150"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.total,
                      layout: "total",
                      page: _vm.listQuery1.pageNum,
                      limit: _vm.listQuery1.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery1, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery1, "pageSize", $event)
                      },
                      pagination: function ($event) {
                        return _vm.getList1(_vm.customer)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("VxeTable", {
            ref: "editTable",
            attrs: {
              temp: _vm.customer,
              list: _vm.list1,
              height: _vm.height / 2 - 30 + _vm.autoHeight,
              "list-loading": _vm.listLoading1,
              "list-query": _vm.listQuery1,
              scroll: { x: 8, y: 8 },
              copyText: "",
              columns: _vm.columns1,
              autoClear: false,
              scrollTopLastLocation: false,
            },
            on: { getdata: _vm.getdata, click: _vm.handleClick },
            scopedSlots: _vm._u([
              {
                key: "ctpFnsdate",
                fn: function (ref) {
                  var column = ref.column
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          placement: "bottom",
                          "popper-class": "time_tip",
                        },
                      },
                      [
                        _c("div", {
                          attrs: { slot: "content" },
                          domProps: { innerHTML: _vm._s(row.showText) },
                          slot: "content",
                        }),
                        _vm._v(" "),
                        row[column.field]
                          ? _c(
                              "div",
                              {
                                staticClass: "el-button v_button ",
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.getFnsList(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row[column.field]) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("div", { staticClass: "total" }, [
            _c("span", [_vm._v("应付金额: " + _vm._s(_vm.account))]),
            _vm._v(" "),
            _c("span", [_vm._v("欠款金额:" + _vm._s(_vm.disamt))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "total" }, [
            _c("span", [
              _vm._v(
                "已付金额: " +
                  _vm._s(_vm.totalPay) +
                  "(现金/卡: " +
                  _vm._s(_vm.cashAddcard) +
                  " 存款: " +
                  _vm._s(_vm.stoamt) +
                  " 券:\n        " +
                  _vm._s(_vm.coup) +
                  ")"
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "table-container",
          style: { height: _vm.height / 3 + "px" },
        },
        [
          _c("el-button", { attrs: { type: "text" } }, [
            _vm._v("电子报单情况列表："),
          ]),
          _vm._v(" "),
          _c("VxeTable", {
            ref: "table",
            attrs: {
              temp: _vm.customer,
              list: _vm.list2,
              height: 50 * (_vm.list2.length + 2),
              "list-loading": _vm.listLoading2,
              "list-query": _vm.listQuery2,
              columns: _vm.columns2,
              copyText: "",
              autoResize: _vm.autoResize,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }